import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './Home';
import Blog from './Blog';
import Games from './Games';
import Contact from './Contact';
const linkStyle = {
  color: '#FFF',
  textDecoration: 'none',
  fontSize: '24px',
  fontWeight: '800',
};
function App() {
  return (
    <Router>
      <div style={{ backgroundColor: 'rgba(0,255,0,1)', height: '100px', width: '100%', position: 'absolute', zIndex:'-1'}}></div>
      <Grid>
        <nav>
          <Row middle="xs" style={{ color: '#fff', height: '100px' }}>
            <Col xs={1}>
              <Link style={linkStyle} to="/">VGMRX</Link>
            </Col>
            <Col xs={1} xsOffset={8}>
              <Link style={linkStyle} to="/blog">Blog</Link>
            </Col>
            <Col xs={1}>
              <Link style={linkStyle} to="/games">Games</Link>
            </Col>
            <Col xs={1}>
              <Link style={linkStyle} to="/contact">Contact</Link>
            </Col>
          </Row>
        </nav>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/games">
            <Games />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </Grid>
    </Router>
  );
}

export default App;
