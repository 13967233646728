import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

function Games() {
  return (
    <div>Games</div>
  );
}

export default Games;
