import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

function Contact() {
  return (
    <div>Contact</div>
  );
}

export default Contact;
