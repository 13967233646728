import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

function Blog() {
  return (
    <div>Blog</div>
  );
}

export default Blog;
